<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Activity header-->
      <div class="card-header border-0 py-4">
        <h4 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-white">
            {{ projectRoom.title }}
          </span>
        </h4>
      </div>
      <div v-if="projectRoom.description != null" class="card-body">
        <p><span v-html="projectRoom.description"></span></p>
      </div>
    </div>
    <!--end::Activity header-->
    <!--begin::Activity Preview Cards-->
    <div
      class="card card-custom gutter-b bg-transparent"
      style="box-shadow: none !important"
    >
      <div class="card-body p-0">
        <div v-if="applicableActivities.length > 0">
          <div v-for="(item, i) in applicableActivities" v-bind:key="i">
            <a :href="activityLink(item)">
              <ActivityPreviewCards
                :activityDetails="item"
              ></ActivityPreviewCards>
            </a>
          </div>
        </div>
        <div v-else>
          <b-alert show variant="transparent">
            <div class="text-center">
              No Activities Found
            </div>
          </b-alert>
        </div>
      </div>
      <!--end::Activity Preview Cards-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActivityPreviewCards from "@/view/pages/activity/ActivityPreviewCards.vue";

export default {
  name: "view_room_activities",
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  watch: {
    pageTitle: {
      handler(val) {
        document.title = (val !== undefined ? val : " ••• ") + " | Input | Together";
      },
      deep: true,
      immediate: true
    }
  },

  components: {
    ActivityPreviewCards
  },
  methods: {
    activityLink(activity) {
      return "/#/activity/" + activity.id;
    }
  },
  computed: {
    ...mapGetters(["activityList", "projectRoomSetupList", "currentUser"]),

    applicableActivities: function() {
      let roomId = this.$route.params.id;

      let activities = this.activityList.filter(function(activity) {
        return (
          activity.system_activity_type_id != 9 &&
          activity.project_room_id == roomId &&
          activity.system_activity_stage_id > 2
        );
      });

      if (this.currentUser.relation_to_project == "participant") {
        activities = activities.filter(function(activity) {
          return activity.system_activity_stage_id > 2;
        });
      }

      return activities;
    },

    projectRoom: function() {
      let roomId = this.$route.params.id;

      return this.projectRoomSetupList[0]["project_rooms"].filter(function(
        projectRoom
      ) {
        return projectRoom.id == roomId;
      })[0];
    },

    pageTitle: function() {
      return this.projectRoom.title;
    }
  }
};
</script>
